import { useEffect, useState } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { appInsights, severityLevel } from 'src/utils/appInsight';
import { CustomHttpClient } from "src/utils/customHttpClient";

const useSignalRConnection = () => {
  const [signalRConnection, setSignalRConnection] = useState(null);
  const getToken: string = localStorage.getItem('accessToken');
  useEffect(() => {
    try {
      // eslint-disable-next-line no-undef
      if (getToken) {
        const { REACT_APP_SOCKET_URL } = process.env;
        const newConnection = new HubConnectionBuilder()
          .withUrl(`${REACT_APP_SOCKET_URL}alertsHub`, {
            accessTokenFactory: () => localStorage.getItem('accessToken'), httpClient: new CustomHttpClient()
          })
          .withAutomaticReconnect()
          .build();
        setSignalRConnection(newConnection);
      }
    } catch (e) {
      appInsights.trackException({
        exception: e,
        severityLevel: severityLevel.Error
      });
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
     {
    signalRConnection
     }
  );
};

export default useSignalRConnection;
