import moment from 'moment';
import mock from 'src/utils/mock';
import type { StationGroup } from 'src/types/stationGroup';
import { layout } from 'src/types/stationGroup';

mock.onGet('/api/stationGroups').reply(() => {
  const stationGroups: StationGroup[] = [
    // {
    //   id: '5e887ac47eed253091be10cb',
    //   name: 'Station Group 1',
    //   locationDescription: 'Hall 1 first floor',
    //   layout: layout.square,
    //   updatedAt: moment()
    //     .subtract(1, 'days')
    //     .subtract(7, 'hours')
    //     .toDate()
    //     .getTime()
    // },
    // {
    //   id: '5e887b209c28ac3dd97f6db5',
    //   name: 'Station Group 2',
    //   locationDescription: 'Hall 1 first floor',
    //   layout: layout.irregular,
    //   updatedAt: moment()
    //     .subtract(1, 'days')
    //     .subtract(7, 'hours')
    //     .toDate()
    //     .getTime()
    // },
    // {
    //   id: '5e887b7602bdbc4dbb234b27',
    //   name: 'Station Group 3',
    //   locationDescription: 'Hall 2 first floor',
    //   layout: layout.horizontal,
    //   updatedAt: moment()
    //     .subtract(1, 'days')
    //     .subtract(6, 'hours')
    //     .toDate()
    //     .getTime()
    // },
    // {
    //   id: '5e86809283e28b96d2d38537',
    //   name: 'Station Group 4',
    //   locationDescription: 'Hall 2 first floor',
    //   layout: layout.vertical,
    //   updatedAt: moment()
    //     .subtract(1, 'days')
    //     .subtract(6, 'hours')
    //     .toDate()
    //     .getTime()
    // },
  ];

  return [200, { stationGroups }];
});

mock.onGet('/api/stationGroups/5e887ac47eed253091be10cb').reply(() => {
  const stationGroup: StationGroup = {
    id: 1,
    name: 'Station Group 1',
    locationDescription: 'Hall 1 first floor',
    layout: layout.square,
    updatedAt: moment()
      .subtract(1, 'days')
      .subtract(7, 'hours')
      .toDate()
      .getTime()
  };

  return [200, { stationGroup }];
});