import { useEffect } from 'react';
import { getSelectedClientId, getSelectedStoreId } from 'src/utils/common';
import browserNotification from '../utils/browserNotification';
import useAuth from './useAuth';
import { appInsights,severityLevel} from 'src/utils/appInsight';
const useSignalRNotificationsListener = (signalRConnection, startConnection = false, isAuthenticated = false): null => {

  const { user } = useAuth();
  useEffect(() => {
    if (signalRConnection?.connectionState === 'Disconnected' && startConnection && isAuthenticated) {
      signalRConnection.start()
        .then(async () => {
          await signalRConnection.send('RegisterClerkConsoleAsync', user.id, getSelectedClientId(), getSelectedStoreId());
          signalRConnection.on('SendPushNotificationToClerkConsoleAsync', (notification: Notification) => {
            browserNotification(notification);
            appInsights.trackTrace({ message:'signalR : Listening to SendPushNotificationToClerkConsoleAsync', properties:{'notification': notification}, severityLevel: severityLevel.Information });
          });
        })
        .catch(error => {
          console.log('SignalR Connection failed: ', error);
          appInsights.trackException({ exception: error, severityLevel: severityLevel.Error });
        });
      return () => {
        signalRConnection.off('SendPushNotificationToManagementConsoleAsync');
        signalRConnection.stop();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalRConnection, startConnection, isAuthenticated]);
  return null;
};

export default useSignalRNotificationsListener;
