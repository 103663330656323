import React, { FC } from 'react';
import useAuth from 'src/hooks/useAuth';
import useSignalRNotificationsConnection from 'src/hooks/useSignalRNotificationsConnection';
import useSignalRNotificationsListener from 'src/hooks/useSignalRNotificationsListener';
const BrowserNotificationsHandler: FC = () => {
  const { signalRConnection } = useSignalRNotificationsConnection();
  const { isAuthenticated } = useAuth();
  useSignalRNotificationsListener(signalRConnection, true, isAuthenticated);
  return (<></>);
};
export default BrowserNotificationsHandler;
