import { Tooltip, Typography, withStyles } from '@material-ui/core';
import type { FC } from 'react';
import React from 'react';
import { Theme } from 'src/theme';
import { truncate } from 'src/utils/common';

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltipArrow: {
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.text.primary,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(10),
    border: `1px solid ${theme.palette.text.primary}`,
  },
  arrow: {
    color: theme.palette.text.primary,
  }
}))(Tooltip);

interface TruncateProps {
  value: string;
  length?: number;
  className?: string;
}

const Truncate: FC<TruncateProps> = ({ value, length = 25 }) => {
  return (
    value.length > length ? <HtmlTooltip
      arrow
      interactive
      title={
        <React.Fragment >
          <Typography color="inherit">{value}</Typography>
        </React.Fragment >
      }
    >
      <span>{truncate(value, length)}</span>
    </HtmlTooltip > : <span>{value}</span>
  );
};

export default Truncate;
