import { Alert } from './alert';
import { StationGroup } from './stationGroup';

export enum StationNetwork {
  Online = 'Online',
  Disconnected = 'Disconnected'
}

export enum StationMode {
  Normal = 'Normal',
  Maintenance = 'Maintenance',
  Diagnostic = 'Diagnostic'
}

export enum StationStatus {
  Ready = 'Ready',
  InUse = 'InUse',
  Sleeping = 'Sleeping', // will remove after discussion
  Resting = 'Resting',
  Disabled = 'Disabled',
  Disconnected = 'Disconnected',
  Diagnostic = 'Diagnostic',
  AssistanceNeeded = 'AssistanceNeeded',
  ShopperAssistance = 'ShopperAssistance',
  Maintenance = 'Maintenance',
  RegisteredAwaitingConnection = 'RegisteredAwaitingConnection'
}

export interface Station {
  id: number;
  permanentId?: string;
  macAddress?: string;
  name: string;
  network?: StationNetwork;
  operatingMode?: StationMode;
  status?: StationStatus;
  alert?: Alert;
  version?: string;
  agentSoftwareVersion?: string;
  installationDate?: string;
  lastUpdateDate?: string;
  currentCpu?: string;
  currentMemory?: string;
  currentThreads?: string;
  antenna?: number;
  stationGroup: StationGroup;
  stationGroupId?: string;
  CreatedOn?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  note?: string;
  currentOrderId?: string;
  currentOrder?: any;
  pos?: string;
  shortCode?: string;
  stationType?: string;
  readerHealthStatus?: any[];
}
export enum StationType {
  Sale = 'Sale',
  Validator = 'Validator'
}

export type StationLogLevel =
  | 'Debug'
  | 'Information'
  | 'Warning'
  | 'Error'
  | 'Fatal';

export enum StationDeltaDirection {
  up = 'up',
  down = 'down'
}

export interface StationSummaryItem {
  count: number;
  deltaDirection: StationDeltaDirection;
  deltaDifference: number;
}

export interface StationSummary {
  id: string;
  stationId: string;
  dateTime?: string;
  totalItems: StationSummaryItem;
  totalOrders: StationSummaryItem;
  readsAverage: StationSummaryItem; // in seconds.
}

export interface StationHistory {
  id: string;
  stationId: string;
  timestamp: string;
  cpu: string;
  threads: string;
  memory: string;
  status: StationStatus;
}
export interface StationLog {
  id: string;
  stationId: string;
  timestamp: string;
  logLevel: StationLogLevel;
  logText: string;
}
export interface StatusDataType {
  name: string;
  text: string;
  textColor: string;
  color: string;
}

export enum StationRFIDReaderStatus {
  Paused = 'Paused',
  Listening = 'Listening',
  Stopped = 'Stopped',
  Disabled = 'Disabled',
  Disconnected = 'Disconnected',
  UnknownReader = 'UnknownReader'
}

export enum RFIDReaderStatusToDisplay {
  Scanning = 'Scanning',
  Ready = 'Ready to Add More Items',
  Stopped = 'Stopped',
  Disabled = 'Disabled',
  Disconnected = 'Disconnected',
  UnknownReader = 'Unknown Reader'
}
export interface TransactionSummary {
  completedTransactionCount: number;
  averagePurchasedItems: number;
  averageScanDuration: number;
  averagePaymentDuration: number;
  averageTransactionDuration: number;
  averageItems: number;
}

export enum CaptureMethod {
  Detected = 'Detected',
  ManuallyAdded = 'ManuallyAdded'
}
