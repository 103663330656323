import { default as jwtDecode, default as jwt_decode } from 'jwt-decode';
import { useSnackbar } from 'notistack';
import type { FC, ReactNode } from 'react';
import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import useSettings from 'src/hooks/useSettings';
import type { User } from 'src/types/user';
import { api } from 'src/utils/api';
import axios from 'src/utils/axios';
import TokenService from 'src/utils/tokenService';
import { appInsights,severityLevel,SetAuthenticatedUserContext} from 'src/utils/appInsight';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT',
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, name: string, password: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { saveSettings } = useSettings();

  const login = async (email: string, password: string) => {
    try {
      let stores = { data: [], status: 0 };
      let userHasSingleStore = false;
      const { data: { token, userName, firstName, lastName, refreshToken }, status } = await api('User/userLogin', 'post', {
        username: email,
        password,
        rememberMe: false,
      }, {}, false);
      appInsights.trackTrace({ message:'Login method called', properties:{'statusCode': status, 'userName': userName}, severityLevel: severityLevel.Information });
      if (status === 200) {
        const decoded: any = jwt_decode(token);
        const { data: { id: userId }, status: statusMe } = await api('User/me', 'get', {}, { Authorization: `Bearer ${token}` });

        if (statusMe === 200) {
          //  If user has single client.
          if (decoded?.allClients === 'false' && decoded?.clients.length === 1) {
            stores = await api(`Stores/${userId}/Clients/${decoded?.clients[0]?.id}`, 'get', {}, { Authorization: `Bearer ${token}` });
            if (stores?.data.length === 1) {
              userHasSingleStore = true;
            }
          }
          // If user has single store.
          if (userHasSingleStore) {
            saveSettings({ store: stores?.data[0]?.storeId, client: decoded?.clients[0]?.id });
          }
          TokenService.setSessionStorage('accessToken', token);
          TokenService.setSessionStorage('refreshToken', refreshToken);
          dispatch({
            type: 'LOGIN',
            payload: {
              user: { id: userId, avatar: '', email: '', name: userName, firstName, lastName }
            }
          });
          SetAuthenticatedUserContext(userName);
        } else {
          enqueueSnackbar('Not able to fetch the user data. Please try again.', {
            variant: 'error',
            autoHideDuration: 3000
          });
          appInsights.trackTrace({ message:'Not able to fetch the user data. Please try again', properties:{'errorCode': status, 'userName': userName}, severityLevel: severityLevel.Information });
        }
      } else if (status === 401) {                         // For unauthorized user
        enqueueSnackbar('Failed to log in. Please try again.', {
          variant: 'error',
          autoHideDuration: 3000
        });
      } else {
        enqueueSnackbar('Some error occurred! Please try again.', {
          variant: 'error',
          autoHideDuration: 3000
        });
      }
    } catch (e) {
      console.error(e);
      appInsights.trackException({ exception: e, severityLevel: severityLevel.Error });
      enqueueSnackbar('Unexpected error has occurred!', {
        variant: 'error',
        autoHideDuration: 3000
      });
    }
  };

  const logout = () => {
    TokenService.setSessionStorage('accessToken', null);
    TokenService.setSessionStorage('refreshToken', null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email: string, name: string, password: string) => {
    const response = await axios.post<{ accessToken: string; user: User; }>('/api/account/register', {
      email,
      name,
      password
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          TokenService.setSessionStorage('accessToken', accessToken);

          const { data: { id, username, email, firstName, lastName }, status } = await api('User/me', 'get');
          if (status === 200) {
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: true,
                user: {
                  id, avatar: '', email, name: username, firstName, lastName
                }
              }
            });
          } else {
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: false,
                user: null
              }
            });
          }
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;