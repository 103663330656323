import React, { FC, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import ClientGuard from 'src/components/ClientGuard';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';

const NotFoundView= lazy(() => import('src/views/errors/NotFoundView'));
const LoginView= lazy(() => import('src/views/auth/LoginView'));
const RegisterView= lazy(() => import('src/views/auth/RegisterView'));
const ClientSelectionView= lazy(() => import('src/views/auth/ClientSelectionView'));
const Dashboard= lazy(() => import('src/views/dashboard/Stations'));
const StationDetailsView= lazy(() => import('src/views/station/StationDetailsView'));
const ItemStatusView = lazy(() => import('src/views/itemstatus/ItemStatusView')); 

export const renderRoutes = (): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      <Route path='/' element={<GuestGuard> <LoginView /> </GuestGuard>}> </Route>
      <Route path='/login-unprotected' element={<LoginView />}> </Route>
      <Route path='/404' element={<NotFoundView />}> </Route>
      <Route path='/register' element={<GuestGuard> <RegisterView /> </GuestGuard>}> </Route>
      <Route path='/register-unprotected' element={<RegisterView />}> </Route>
      <Route path='/choose-client' element={<AuthGuard><ClientSelectionView /></AuthGuard>}> </Route>
      <Route path='/app' element={<AuthGuard> <ClientGuard> <DashboardLayout /> </ClientGuard> </AuthGuard>}>
        <Route path='dashboard' element={<Dashboard />}> </Route>
        <Route path='item-status-view' element={<ItemStatusView />} />
        <Route path='stations/:id' element={<StationDetailsView />}> </Route>
        <Route index element={<Navigate to="/404" /> } />
        <Route path='*' element={<Navigate to="/404" />}> </Route>
      </Route>
      <Route path='*' element={<Navigate to="/404" />}> </Route>
    </Routes>
   
  </Suspense>
);

const ClerkRoutes: FC = () => {
  return (
    renderRoutes()
  );
};

export default ClerkRoutes;
