import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { Transaction } from 'src/types/transaction';
import genericActions, { emptyListingObject } from './common';

interface ReduxStoreListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: Transaction[],
  pageSize: number,
  skip: number,
}
export interface ReduxStoreObject {
  records?: ReduxStoreListObject;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
}

interface TransactionsState {
  recentTransactions: Transaction[];
  transactions: Transaction[];
  error: boolean;
  message: string;
  loading: boolean;
  loadingMore: boolean;
  isMoreTrxAvailable: boolean;
}

const initialState: ReduxStoreObject = {
  records: emptyListingObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const emptyObject = [];

const slice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    getAll(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records, error, message, loading } = action.payload;

      state.records = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAll';
    },
    resetError(state: ReduxStoreObject, action: PayloadAction<{}>) {
      state.error = false;
      state.message = '';
      state.actionType = 'resetError';
    },
    resetSuccess(state: ReduxStoreObject, action: PayloadAction<{}>) {
      state.success = false;
      state.message = '';
      state.actionType = 'resetSuccess';
    },
    setLoading(state: ReduxStoreObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
  }
});

const resource = 'Transactions';
export const reducer = slice.reducer;
export const { getAll, get, set, put, patch, remove, resetError, resetSuccess } = genericActions(slice.actions, resource, emptyObject);
export default slice;