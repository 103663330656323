import type { Station } from './station';
import type { User } from './user';

export enum AlertType {
  AssistanceNeeded = 'AssistanceNeeded',
  InputRequired = 'InputRequired'
}

export enum AlertInputType {
  stringType = 'string',
  dateType = 'date',
  intType = 'integer'
}

export interface Alert {
  id: string;
  station?: Station;
  stationId?: string;
  name?: string;
  code: string;
  text: string;
  type: AlertType;
  closedBy?: User;
  createdAt?: string;
  permanentId?: string;
  createdOn?: string;
  modifiedOn?: string;
  createdBy?: User;
  modifiedBy?: User;
  version?: string;
  inputType?: AlertInputType;
  inputLabel?: string;
}