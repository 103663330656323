import { useSnackbar } from 'notistack';
import {  useNavigate } from 'react-router-dom';
import { restoreSettings, storeSettings } from 'src/contexts/SettingsContext';
import useAuth from './useAuth';
import { appInsights,severityLevel} from 'src/utils/appInsight';

export const useLogout = () => {

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const doLogout = async (): Promise<void> => {
    try {
      await logout();
      const settings = restoreSettings();
      if (settings) {
        delete settings.client;
        delete settings.store;
        storeSettings(settings);
      }
      navigate('/');
    } catch (err) {
      appInsights.trackException({ exception: err, severityLevel: severityLevel.Error });
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };
  return doLogout;
};