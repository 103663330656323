import mock from 'src/utils/mock';
import { Station, StationLog, StationHistory, StationSummary, StationDeltaDirection, StationStatus, StationMode, StationNetwork } from 'src/types/station';
import { layout } from 'src/types/stationGroup';
import moment from 'moment';
import { AlertType } from 'src/types/alert';

mock.onGet('/api/stations').reply(() => {
  const stations: Station[] = [
    // {
    //   id: 'ANI-Register-1',
    //   macAddress: '51-7F-69-B7-20-43',
    //   name: 'ANI-Register-1',
    //   network: StationNetwork.online,
    //   status: StationStatus.Ready,
    //   operatingMode: StationMode.normal,
    //   alert: {
    //     id: '38956428965894365874365',
    //     code: "123456",
    //     text: "Alert 123",
    //     type: AlertType.ShopperAssistance,
    //   },
    //   agentSoftwareVersion: '1.0.7',
    //   installationDate: '2021-01-01',
    //   lastUpdateDate: '2021-02-01',
    //   currentCpu: 'i7',
    //   currentMemory: '16 GB',
    //   currentThreads: '12',
    //   antenna: 4,
    //   stationGroup: {
    //     id: '5e887ac47eed253091be10cb',
    //     name: 'EXO-Go-Lab-1',
    //     locationDescription: 'Hall 1 first floor',
    //     layout: layout.square,
    //     updatedAt: moment()
    //       .subtract(1, 'days')
    //       .subtract(7, 'hours')
    //       .toDate()
    //       .getTime()
    //   },
    // },
    // {
    //   id: 'EXO-Go-Lab-1',
    //   macAddress: '9D-A6-43-47-F5-BB',
    //   name: 'EXO-Go-Lab-1',
    //   network: StationNetwork.online,
    //   status: StationStatus.Ready,
    //   operatingMode: StationMode.normal,
    //   alert: null,
    //   agentSoftwareVersion: '1.0.7',
    //   installationDate: '2021-01-01',
    //   lastUpdateDate: '2021-02-01',
    //   currentCpu: 'i7',
    //   currentMemory: '16 GB',
    //   currentThreads: '12',
    //   antenna: 4,
    //   stationGroup: {
    //     id: '5e887ac436476753091be10cb',
    //     name: 'Front Station Group',
    //     locationDescription: 'Hall 1 first floor',
    //     layout: layout.square,
    //     updatedAt: moment()
    //       .subtract(1, 'days')
    //       .subtract(7, 'hours')
    //       .toDate()
    //       .getTime()
    //   },
    // },
    // {
    //   id: 'Register-1',
    //   macAddress: 'FE-78-29-E1-A2-D0',
    //   name: 'Register-1',
    //   network: StationNetwork.online,
    //   status: StationStatus.Ready,
    //   operatingMode: StationMode.normal,
    //   alert: null,
    //   agentSoftwareVersion: '1.0.6',
    //   installationDate: '2021-01-01',
    //   lastUpdateDate: '2021-02-01',
    //   currentCpu: 'i5',
    //   currentMemory: '12 GB',
    //   currentThreads: '8',
    //   antenna: 2,
    //   stationGroup: {
    //     id: '5e887ac47eed253091be10cb',
    //     name: 'Front Station Group',
    //     locationDescription: 'Hall 1 first floor',
    //     layout: layout.square,
    //     updatedAt: moment()
    //       .subtract(1, 'days')
    //       .subtract(7, 'hours')
    //       .toDate()
    //       .getTime()
    //   },
    // }
  ];

  return [200, { stations }];

});

mock.onGet('/api/stations/5ecb868d046543390ef3ac62c').reply(() => {
  const station: Station = {
    id: 1,
    macAddress: '51-7F-69-B7-20-43',
    name: 'Clone-I',
    network: StationNetwork.Online,
    status: StationStatus.Ready,
    operatingMode: StationMode.Normal,
    alert: {
      id: '38956428965894365874365',
      code: '123456',
      text: 'Alert 123',
      type: AlertType.AssistanceNeeded,
    },
    agentSoftwareVersion: '1.0.7',
    installationDate: '2021-01-01',
    lastUpdateDate: '2021-02-01',
    currentCpu: 'i7',
    currentMemory: '16 GB',
    currentThreads: '12',
    antenna: 4,
    stationGroup: {
      id: 1,
      name: 'Front Station Group',
      locationDescription: 'Hall 1 first floor',
      layout: layout.square,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    },
  };

  return [200, { station }];

});

mock.onGet('/api/stations/5ecb868d045454390ef3ac62c').reply(() => {
  const station: Station = {
    id: 1,
    macAddress: '9D-A6-43-47-F5-BB',
    name: 'Ramp Aria',
    network: StationNetwork.Online,
    status: StationStatus.InUse,
    operatingMode: StationMode.Normal,
    alert: null,
    agentSoftwareVersion: '1.0.7',
    installationDate: '2021-01-01',
    lastUpdateDate: '2021-02-01',
    currentCpu: 'i7',
    currentMemory: '16 GB',
    currentThreads: '12',
    antenna: 4,
    stationGroup: {
      id: 1,
      name: 'Front Station Group',
      locationDescription: 'Hall 1 first floor',
      layout: layout.square,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    },
  };

  return [200, { station }];
});

mock.onGet('/api/stations/5ecb868776987ef3ac62c').reply(() => {
  const station: Station = {
    id: 1,
    macAddress: 'FE-78-29-E1-A2-D0',
    name: 'Am Ant Ash',
    network: StationNetwork.Online,
    status: StationStatus.Ready,
    operatingMode: StationMode.Normal,
    alert: null,
    agentSoftwareVersion: '1.0.6',
    installationDate: '2021-01-01',
    lastUpdateDate: '2021-02-01',
    currentCpu: 'i5',
    currentMemory: '12 GB',
    currentThreads: '8',
    antenna: 2,
    stationGroup: {
      id: 1,
      name: 'Front Station Group',
      locationDescription: 'Hall 1 first floor',
      layout: layout.square,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    },
  };

  return [200, { station }];
});

mock.onGet('/api/stations/5ecb862433637390ef5ac62c').reply(() => {
  const station: Station = {
    id: 1,
    macAddress: 'BD-F4-44-0E-B9-7E',
    name: 'Avid-D',
    network: StationNetwork.Online,
    status: StationStatus.Ready,
    operatingMode: StationMode.Normal,
    alert: null,
    agentSoftwareVersion: '1.0.7',
    installationDate: '2021-01-01',
    lastUpdateDate: '2021-02-01',
    currentCpu: 'i3',
    currentMemory: '8 GB',
    currentThreads: '6',
    antenna: 2,
    stationGroup: {
      id: 1,
      name: 'Front Station Group',
      locationDescription: 'Hall 1 first floor',
      layout: layout.square,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    },
  };

  return [200, { station }];
});

mock.onGet('/api/stations/5ecb862433637390ef5ac62c/summary').reply(() => {
  const stationSummary: StationSummary = {
    id: 'safsdgfsd',
    stationId: '5ecb862433637390ef5ac62c',
    dateTime: moment()
      .subtract(32, 'minutes')
      .format('YYYY-MM-DD HH:mm:ss'),
    totalItems: {
      count: 2,
      deltaDirection: StationDeltaDirection.up,
      deltaDifference: 4
    },
    totalOrders: {
      count: 4,
      deltaDirection: StationDeltaDirection.up,
      deltaDifference: 3
    },
    readsAverage: {
      count: 20, // In Seconds
      deltaDirection: StationDeltaDirection.up,
      deltaDifference: 2
    },
  };

  return [200, { stationSummary }];
});

mock.onGet('/api/stations/5ecb868d0f437390ef3ac62c/history').reply(() => {
  const stationHistory: StationHistory[] = [
    {
      id: '5ecb868d0f437390ef3a7878',
      stationId: '5ecb868d0f437390ef3ac62c',
      timestamp: '2013-07-22 12:50:05',
      cpu: 'i7',
      threads: '12',
      memory: '16 GB',
      status: StationStatus.Ready,
    },
    {
      id: '5ecb868ddf437390edfa7878',
      stationId: '5ecb868d0f437390ef3ac62c',
      timestamp: '2013-07-22 12:50:05',
      cpu: 'i7',
      threads: '12',
      memory: '16 GB',
      status: StationStatus.Ready,
    },
    {
      id: '5ecb868d0f4df390ef3a7878',
      stationId: '5ecb868d0f437390ef3ac62c',
      timestamp: '2013-07-22 12:50:05',
      cpu: 'i7',
      threads: '12',
      memory: '16 GB',
      status: StationStatus.Ready,
    },
    {
      id: '5ecb868d0f437390ef3a78df',
      stationId: '5ecb868d0f437390ef3ac62c',
      timestamp: '2013-07-22 12:50:05',
      cpu: 'i7',
      threads: '12',
      memory: '16 GB',
      status: StationStatus.Ready,
    },
    {
      id: '5ecbfgh8d0f437390ef3a7878',
      stationId: '5ecb868d0f437390ef3ac62c',
      timestamp: '2013-07-22 12:50:05',
      cpu: 'i7',
      threads: '12',
      memory: '16 GB',
      status: StationStatus.Ready,
    },
    {
      id: '5ecb868d0f467390ef3a7878',
      stationId: '5ecb868d0f437390ef3ac62c',
      timestamp: '2013-07-22 12:50:05',
      cpu: 'i7',
      threads: '12',
      memory: '16 GB',
      status: StationStatus.Ready,
    },
  ];

  return [200, { stationHistory }];
});

mock.onGet('/api/stations/5ecb868d0f437390ef3ac62c/logs').reply(() => {
  const stationLogs: StationLog[] = [
    // {
    //   id: '5ecb868d0f437390ef3a7878',
    //   stationId: '5ecb868d0f437390ef3ac62c',
    //   timestamp: '2013-07-22 12:50:05',
    //   logLevel: 'Debug',
    //   logText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    // },
    // {
    //   id: '5ecb868d0f437390ef3a7878',
    //   stationId: '5ecb868d0f437390ef3ac62c',
    //   timestamp: '2013-07-22 12:50:05',
    //   logLevel: 'Information',
    //   logText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    // },
    // {
    //   id: '5ecb868d0f437390ef3a7878',
    //   stationId: '5ecb868d0f437390ef3ac62c',
    //   timestamp: '2013-07-22 12:50:05',
    //   logLevel: 'Warning',
    //   logText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    // },
    // {
    //   id: '5ecb868d0f437390ef3a7878',
    //   stationId: '5ecb868d0f437390ef3ac62c',
    //   timestamp: '2013-07-22 12:50:05',
    //   logLevel: 'Error',
    //   logText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    // },
    // {
    //   id: '5ecb868d0f437390ef3a7878',
    //   stationId: '5ecb868d0f437390ef3ac62c',
    //   timestamp: '2013-07-22 12:50:05',
    //   logLevel: 'Fatal',
    //   logText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    // },
    // {
    //   id: '5ecb868d0f437390ef3a7878',
    //   stationId: '5ecb868d0f437390ef3ac62c',
    //   timestamp: '2013-07-22 12:50:05',
    //   logLevel: 'Debug',
    //   logText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    // },
  ];

  return [200, { stationLogs }];
});