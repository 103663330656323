import mock from 'src/utils/mock';
// import { StationMode, StationNetwork, StationStatus } from 'src/types/station';
// import { layout } from 'src/types/stationGroup';
// import moment from 'moment';
import { Transaction } from 'src/types/transaction';
// import { ProductType } from 'src/types/product';
// import { AlertType } from 'src/types/alert';

mock.onGet('/api/station/StationStatus.Readyef3ac62c/transactions').reply(() => {
  const transactions: Transaction[] = [
    // {
    //   id: '5ecb867835jhfg895tf3ac62c',
    //   items: [
    //     {
    //       id: '865478246545436576478436',
    //       quantity: 2,
    //       product: {
    //         id: '5ece2c077e39da27658aa8a9',
    //         name: 'Cap',
    //         description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.',
    //         epcPrefix: 'abc',
    //         sku: '123',
    //         type: ProductType.type1,
    //         iconUrl: '',
    //         imageUrl: 'https://assets.ajio.com/medias/sys_master/root/hb5/h1e/13857996472350/-1117Wx1400H-460411581-white-OUTFIT.jpg',
    //         manufacturer: 'Addidas',
    //         upc: '1234567',
    //         updatedAt: moment()
    //           .subtract(6, 'hours')
    //           .toDate()
    //           .getTime(),
    //       },
    //       productId: '28475984759887435873325425'
    //     },
    //   ],
    //   itemsCount: 2,
    //   summary: '2 items read in 1s',
    //   createdOn: moment()
    //     .subtract(2, 'minutes')
    //     .format('YYYY-MM-DD HH:mm:ss'),
    //   readTime: 2,
    //   stationId: '5ecb868d046543390ef3ac62c',
    //   station: {
    //     id: '5ecb868d046543390ef3ac62c',
    //     macAddress: '51-7F-69-B7-20-43',
    //     name: 'Clone-I',
    //     network: StationNetwork.online,
    //     status: StationStatus.Ready,
    //     operatingMode: StationMode.normal,
    //     alert: {
    //       id: '38956428965894365874365',
    //       code: "123456",
    //       text: "Alert 123",
    //       type: AlertType.shopperAssistance,
    //     },
    //     agentSoftwareVersion: '1.0.7',
    //     installationDate: '2021-01-01',
    //     lastUpdateDate: '2021-02-01',
    //     currentCpu: 'i7',
    //     currentMemory: '16 GB',
    //     currentThreads: '12',
    //     antenna: 4,
    //     stationGroup: {
    //       id: '5e887ac47eed253091be10cb',
    //       name: 'Front Station Group',
    //       locationDescription: 'Hall 1 first floor',
    //       layout: layout.square,
    //       updatedAt: moment()
    //         .subtract(1, 'days')
    //         .subtract(7, 'hours')
    //         .toDate()
    //         .getTime()
    //     },
    //   },
    // },
    // {
    //   id: '5ecb85645676765g895tf3ac62c',
    //   items: [
    //     {
    //       id: '865478246545436576478436',
    //       quantity: 4,
    //       product: {
    //         id: '5ece2c077e39da27658aa8a9',
    //         name: 'Cap',
    //         description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.',
    //         epcPrefix: 'abc',
    //         sku: '123',
    //         type: ProductType.type1,
    //         iconUrl: '',
    //         imageUrl: 'https://assets.ajio.com/medias/sys_master/root/hb5/h1e/13857996472350/-1117Wx1400H-460411581-white-OUTFIT.jpg',
    //         manufacturer: 'Addidas',
    //         upc: '1234567',
    //         updatedAt: moment()
    //           .subtract(6, 'hours')
    //           .toDate()
    //           .getTime(),
    //       },
    //       productId: '28475984759887435873325425'
    //     },
    //   ],
    //   itemsCount: 4,
    //   summary: '4 items read in 1s',
    //   createdOn: moment()
    //     .subtract(2, 'minutes')
    //     .format('YYYY-MM-DD HH:mm:ss'),
    //   readTime: 2,
    //   stationId: '5ecb868d046543390ef3ac62c',
    //   station: {
    //     id: '5ecb868d046543390ef3ac62c',
    //     macAddress: '51-7F-69-B7-20-43',
    //     name: 'Clone-I',
    //     network: StationNetwork.online,
    //     status: StationStatus.Ready,
    //     operatingMode: StationMode.normal,
    //     alert: {
    //       id: '38956428965894365874365',
    //       code: "123456",
    //       text: "Alert 123",
    //       type: AlertType.shopperAssistance,
    //     },
    //     agentSoftwareVersion: '1.0.7',
    //     installationDate: '2021-01-01',
    //     lastUpdateDate: '2021-02-01',
    //     currentCpu: 'i7',
    //     currentMemory: '16 GB',
    //     currentThreads: '12',
    //     antenna: 4,
    //     stationGroup: {
    //       id: '5e887ac47eed253091be10cb',
    //       name: 'Front Station Group',
    //       locationDescription: 'Hall 1 first floor',
    //       layout: layout.square,
    //       updatedAt: moment()
    //         .subtract(1, 'days')
    //         .subtract(7, 'hours')
    //         .toDate()
    //         .getTime()
    //     },
    //   },
    // },
    // {
    //   id: '5ecb866869870989808f3ac62c',
    //   items: [
    //     {
    //       id: '865478246545436576478436',
    //       quantity: 6,
    //       product: {
    //         id: '5ece2c077e39da27658aa8a9',
    //         name: 'Cap',
    //         description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.',
    //         epcPrefix: 'abc',
    //         sku: '123',
    //         type: ProductType.type1,
    //         iconUrl: '',
    //         imageUrl: 'https://assets.ajio.com/medias/sys_master/root/hb5/h1e/13857996472350/-1117Wx1400H-460411581-white-OUTFIT.jpg',
    //         manufacturer: 'Addidas',
    //         upc: '1234567',
    //         updatedAt: moment()
    //           .subtract(6, 'hours')
    //           .toDate()
    //           .getTime(),
    //       },
    //       productId: '28475984759887435873325425'
    //     },
    //   ],
    //   itemsCount: 6,
    //   summary: '4 items read in 1s',
    //   createdOn: moment()
    //     .subtract(5, 'minutes')
    //     .format('YYYY-MM-DD HH:mm:ss'),
    //   readTime: 2,
    //   stationId: '5ecb868d046543390ef3ac62c',
    //   station: {
    //     id: '5ecb868d046543390ef3ac62c',
    //     macAddress: '51-7F-69-B7-20-43',
    //     name: 'Clone-I',
    //     network: StationNetwork.online,
    //     status: StationStatus.Ready,
    //     operatingMode: StationMode.normal,
    //     alert: {
    //       id: '38956428965894365874365',
    //       code: "123456",
    //       text: "Alert 123",
    //       type: AlertType.shopperAssistance,
    //     },
    //     agentSoftwareVersion: '1.0.7',
    //     installationDate: '2021-01-01',
    //     lastUpdateDate: '2021-02-01',
    //     currentCpu: 'i7',
    //     currentMemory: '16 GB',
    //     currentThreads: '12',
    //     antenna: 4,
    //     stationGroup: {
    //       id: '5e887ac47eed253091be10cb',
    //       name: 'Front Station Group',
    //       locationDescription: 'Hall 1 first floor',
    //       layout: layout.square,
    //       updatedAt: moment()
    //         .subtract(1, 'days')
    //         .subtract(7, 'hours')
    //         .toDate()
    //         .getTime()
    //     },
    //   },
    // },
  ];

  return [200, { transactions }];
});