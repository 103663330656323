import { Station } from './station';
import { Product } from './product';

export interface Item {
  id: string,
  quantity: number,
  product?: Product,
  productId: string,
}

export interface TransactionsRequestPayload {
  stationId: number,
  lastTransactionId?: number | null,
  limit?: number,
  date?: string,
}

export enum TransactionStatus {
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  CancelledByReset = 'CancelledByReset',
  Suspended = 'Suspended',
  InProgress = 'InProgress',
  Voided = 'Voided'
}

export interface Transaction {
  id: string;
  items: Item[];
  itemsCount: number,
  summary?: string,
  status?: TransactionStatus,
  readTime?: number, //in seconds
  readDuration?: number, //in seconds
  station?: Station,
  stationId: string,
  modifiedOn?: string,
  startTime?: string,
  externalId?: string,
  referenceNumber?: string,
  voidedFrom?: string,
  createdOn?: string,
  startedAt?: string,
  completedAt?: string,
  validItemCount?: string,
}