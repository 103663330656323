import { useEffect, useState } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { CustomHttpClient } from "src/utils/customHttpClient";

const useSignalRNotificationsConnection = () => {
  const [signalRConnection, setSignalRConnection] = useState(null);
  const getToken: string = localStorage.getItem('accessToken');

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (getToken) {
      const { REACT_APP_SOCKET_URL } = process.env;
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${REACT_APP_SOCKET_URL}pushNotificationsHub`, {
          accessTokenFactory: () => localStorage.getItem('accessToken'), httpClient: new CustomHttpClient()
        })
        .withAutomaticReconnect()
        .build();

      setSignalRConnection(newConnection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return ( 
    {
    signalRConnection
    }
  );
};

export default useSignalRNotificationsConnection;
