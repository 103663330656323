import moment from 'moment';
import mock from 'src/utils/mock';
import type {
  Store,
} from 'src/types/store';

mock.onGet('/api/stores').reply(() => {
  const stores: Store[] = [
    {
      id: '5e887ac47eed253091be10cb',
      name: 'Freddie Sutton',
      address: {
        address_line: 'Spring Haven Trail',
        suite: '2919',
        city: 'Summit',
        state: 'NJ',
        zip: '07901',
      },
      contact_phone: '973-701-4501',
      contact_email: '2jazo3u0uco@temporary-mail.net',
      contact_name: 'Freddie Sutton',
      notes: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      client_id: 1,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    },
    {
      id: '5e887ac47eed253091be10cd',
      name: 'Gregory T Bourgoin',
      address: {
        address_line: 'Loving Acres Road',
        suite: '3091',
        city: 'York',
        state: 'Pennsylvania',
        zip: '17403',
      },
      contact_phone: '717-747-5948',
      contact_email: 'f6y6t9c06oo@temporary-mail.net',
      contact_name: 'Gregory T Bourgoin',
      notes: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      client_id: 1,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    },
    {
      id: '5e887ac47eed253091be10cf',
      name: 'James M Blake',
      address: {
        address_line: 'West Fork Drive',
        suite: '2726',
        city: 'Miami',
        state: 'NJ',
        zip: '07901',
      },
      contact_phone: '954-401-3348',
      contact_email: 'wilvfhhco1@temporary-mail.net',
      contact_name: 'Freddie Sutton',
      notes: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      client_id: 1,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    },
    {
      id: '5e887ac47eed253091be10cg',
      name: 'Wanda W Goodin',
      address: {
        address_line: 'Sarah Drive',
        suite: '3687',
        city: 'Lafayette',
        state: 'Louisiana',
        zip: '70506',
      },
      contact_phone: '973-701-4501',
      contact_email: '2jazo3u0uco@temporary-mail.net',
      contact_name: 'Freddie Sutton',
      notes: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      client_id: 1,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    },
    {
      id: '5e887ac47eed253091be10ch',
      name: 'David C Hodge',
      address: {
        address_line: 'Spring Street',
        suite: '4570',
        city: 'Taylorville',
        state: 'Illinois',
        zip: '62568',
      },
      contact_phone: '217-820-2273',
      contact_email: 'eglmldvdslv@temporary-mail.net',
      contact_name: 'Freddie Sutton',
      notes: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      client_id: 1,
      updatedAt: moment()
        .subtract(1, 'days')
        .subtract(7, 'hours')
        .toDate()
        .getTime()
    },
  ];

  return [200, { stores }];
});

mock.onGet('/api/stores/5e887ac47eed253091be10cb').reply(() => {
  const store: Store = {
    id: '5e887ac47eed253091be10cb',
    name: 'Freddie Sutton',
    address: {
      address_line: 'Spring Haven Trail',
      suite: '2919',
      city: 'Summit',
      state: 'NJ',
      zip: '07901',
    },
    contact_phone: '973-701-4501',
    contact_email: '2jazo3u0uco@temporary-mail.net',
    contact_name: 'Freddie Sutton',
    notes: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    client_id: 1,
    updatedAt: moment()
      .subtract(1, 'days')
      .subtract(7, 'hours')
      .toDate()
      .getTime()
  };
  return [200, { store }];
});