import React from 'react';
import type { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import { initCustomLocal } from 'src/utils/moment';
import { AlertsProvider } from 'src/contexts/StationAlertsContext';
import UpdateHandler from './updateHandler';
import BrowserNotificationsHandler from './components/BrowserNotificationsHandler';
import { SocketProvider } from './contexts/SocketContext';
import ClerkRoutes from 'src/routes';

const jss = create({ plugins: [ ...jssPreset().plugins, rtl() ] });
const App: FC = () => {
  const { settings } = useSettings();
  initCustomLocal();
  const theme = createTheme({
         direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={ theme }>
      <StylesProvider jss={ jss }>
        <MuiPickersUtilsProvider utils={ MomentUtils }>
          <SnackbarProvider dense maxSnack={ 3 }>
            <UpdateHandler>
              <BrowserRouter>
                <AuthProvider>
                  <SocketProvider>
                    <AlertsProvider>
                      <BrowserNotificationsHandler />
                      <GlobalStyles />
                      <ScrollReset />
                      <GoogleAnalytics />
                      <ClerkRoutes />
                    </AlertsProvider>
                  </SocketProvider>
                </AuthProvider>
              </BrowserRouter>
            </UpdateHandler>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
