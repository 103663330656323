import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Station as StationInterface } from 'src/types/station';
import genericActions from './common';

interface ReduxStoreListObject {
  page: number,
  totalPages: number,
  totalCount: number,
  rows: StationInterface[],
  pageSize: number,
  skip: number,
}
export interface ReduxStoreObject {
  records?: ReduxStoreListObject;
  selectedRecord?: StationInterface;
  error: boolean;
  success: boolean;
  message: string;
  loading: boolean;
  actionType?: string;
}

const emptyObject = {
  id: 0,
  name: '',
  stationGroup: {
    id: 0,
    name: '',
  },
};

const initialState: ReduxStoreObject = {
  records: {
    page: -1,
    totalPages: 1,
    totalCount: 1,
    rows: [],
    pageSize: 1,
    skip: 0,
  },
  selectedRecord: emptyObject,
  error: false,
  success: false,
  message: '',
  loading: false,
  actionType: '',
};

const slice = createSlice({
  name: 'stations',
  initialState,
  reducers: {
    getAll(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { records, error, message, loading } = action.payload;

      state.records = records;
      state.error = error;
      state.message = message;
      state.loading = loading;
      state.actionType = 'getAll';
    },
    get(state: ReduxStoreObject, action: PayloadAction<ReduxStoreObject>) {
      const { selectedRecord, error, message, loading } = action.payload;

      if (selectedRecord) {
        state.selectedRecord = selectedRecord;
        state.error = error;
        state.message = message;
        state.loading = loading;
        state.actionType = 'get';
      }
    },
    set(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.actionType = 'set';
      if (loading !== null)
        state.loading = loading;
    },
    remove(state: ReduxStoreObject, action: PayloadAction<{ error: boolean; success: boolean; message: string; loading: boolean; }>) {
      const { success, error, message, loading } = action.payload;
      state.error = error;
      state.success = success;
      state.message = message;
      state.loading = loading;
      state.actionType = 'remove';
    },
    resetError(state: ReduxStoreObject) {
      state.error = false;
      state.message = '';
    },

    resetSuccess(state: ReduxStoreObject) {
      state.success = false;
      state.message = '';
    },
    setLoading(state: ReduxStoreObject, action: PayloadAction<{ loading: boolean; }>) {
      const { loading } = action.payload;
      state.loading = loading;
      state.actionType = 'loading';
    },
    resetSelected(state: ReduxStoreObject) {
      state.selectedRecord = emptyObject;
    }
  }
});

export const reducer = slice.reducer;

export const { getAll, get, set, put, patch, remove, resetError, resetSuccess } = genericActions(slice.actions, 'stations', emptyObject);

export default slice;