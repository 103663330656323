import { Store } from './store';

export enum layout {
  square = 'SQUARE',
  horizontal = 'HORIZONTAL',
  vertical = 'VERTICAL',
  irregular = 'IRREGULAR',
}

export interface StationGroup {
  id: number;
  name: string,
  locationDescription?: string,
  layout?: layout,
  updatedAt?: number;
  store?: Store;
}