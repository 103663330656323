export type InventoryType =
  | 'in_stock'
  | 'limited'
  | 'out_of_stock';

export enum ProductType {
  type1 = 'type1',
  type2 = 'type2',
  type3 = 'type3',
  type4 = 'type4',
}

export interface ProductOld {
  id: string;
  attributes: string[];
  category: string;
  createdAt: number;
  currency: string;
  image: string | null;
  inventoryType: InventoryType;
  isAvailable: boolean;
  isShippable: boolean;
  name: string;
  price: number;
  quantity: number;
  updatedAt: number;
  variants: number;
}

export interface Product {
  id: string;
  name: string;
  description: string;
  sku: string;
  upc: string;
  epcPrefix: string;
  iconUrl: string;
  imageUrl: string;
  manufacturer: string;
  type: ProductType;
  updatedAt: number;
}

export interface ProductStatus {
  id: string;
  name: string;
  description: string;
  sku: string;
  upc: string;
  status: string;
  price: number;
}

export enum ItemStatus {
  New       = 'New',
  Voided    = 'Voided',
  Returned  = 'Returned',
  Purchased = 'Purchased',
}