import { Badge, Box, Button, FormControl, FormHelperText, IconButton, makeStyles, Popover, SvgIcon, TextField, Tooltip, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { capitalCase } from 'change-case';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { Settings as SettingsIcon } from 'react-feather';
import Truncate from 'src/components/Truncate';
import { THEMES } from 'src/constants';
import useGetClients from 'src/hooks/useGetClients';
import useSettings from 'src/hooks/useSettings';
import type { Theme } from 'src/theme';
import { api } from 'src/utils/api';
import { logToCloud } from 'src/utils/common';
interface DDValue {
  id: number;
  name: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  },
  formControl: {
    minWidth: 120,
  },
  errorTextColor: {
    color: 'red'
  }
}));

const Settings: FC = ({
  ...rest
}) => {
  const classes = useStyles();
  const ref = useRef<any>(null);
  const { settings, saveSettings } = useSettings();
  const clients = useGetClients();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState(settings);
  const [stores, setStores] = useState<{ id: number; name: string; }[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isError, setIsError] = useState<boolean>(false);
  const prevSettingsClient = useRef(settings.client);

  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    fetchStores(settings.client);
    setValues(settings);
    setOpen(false);
  };

  const handleChange = (field, value, name): void => {
    if (field === 'client') {
      setValues({
        ...values,
        client: value,
        clientName: name,
        store: 0,
        storeName: ''
      });
      fetchStores(value);
    } else if (field === 'store') {
      setIsError(false);
      setValues({
        ...values,
        store: value,
        storeName: name
      });
    } else if (field === 'theme') {
      setValues({
        ...values,
        theme: value,
      });
    }
  };

  const handleSave = (): void => {
    if (values.store === 0) {
      setIsError(true);
      setOpen(true);
    } else {
      saveSettings(values);
      window.location.href = '/app/dashboard';
      setOpen(false);
    }
  };

  const fetchStores = async (clientId) => {
    const { data: { rows }, status } = await api('Stores', 'get', { clientId });
    if (status === 200) {
      const storesMapped = rows?.map(item => ({ id: item?.id, name: item?.name }));
      setStores(storesMapped);
    } else {
      enqueueSnackbar('Stores request has failed.', {
        variant: 'error',
        autoHideDuration: 3000
      });
    }
  };

  useEffect(() => {
    if (settings.client !== prevSettingsClient.current) {
      const { client } = settings;
      prevSettingsClient.current = client;
      logToCloud({ message: 'Client has been changed', data: { client } });
      window.location.reload();
    }
    fetchStores(settings.client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return (
    <>
      <Tooltip title="Settings">
        <Badge
          color="secondary"
          variant="dot"
          classes={{ badge: classes.badge }}
        >
          <IconButton
            color="inherit"
            onClick={handleOpen}
            ref={ref}
          >
            <SvgIcon fontSize="small">
              <SettingsIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant="h4"
          color="textPrimary"
        >
          Settings
        </Typography>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Theme"
            name="theme"
            onChange={(event) => handleChange('theme', event.target.value, '')}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option
                key={theme}
                value={theme}
              >
                {capitalCase(theme)}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <Autocomplete
              id="client"
              value={clients.find(item => item.id === values.client)}
              options={clients as DDValue[]}
              getOptionLabel={(option: DDValue) => option.name}
              onChange={(e, value: DDValue) => {
                handleChange('client', value?.id || null, value?.name || '');
              }}
              autoHighlight
              disableClearable
              renderOption={({ name, ...props }) => {
                return (
                  <Truncate value={name} length={50} />
                );
              }}
              renderInput={(params) => {
                return <TextField
                  {...params}
                  fullWidth
                  label="Client"
                  name="client"
                  variant="outlined" />;
              }}
            />
          </FormControl>
        </Box>
        <Box mt={2}>
          <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <Autocomplete
              id="store"
              value={stores.find(item => item.id === values.store)}
              options={stores as DDValue[]}
              getOptionLabel={(option: DDValue) => option.name}
              onChange={(e, value: DDValue) => {
                handleChange('store', value?.id || null, value?.name || '');
              }}
              autoHighlight
              disableClearable
              renderOption={({ name, ...props }) => {
                return (
                  <Truncate value={name} length={50} />
                );
              }}
              renderInput={(params) => {
                return <TextField
                  {...params}
                  fullWidth
                  label="Store"
                  name="store"
                  variant="outlined" />;
              }}
            />
            <FormHelperText className={classes.errorTextColor}>{isError ? 'Store selection is required.' : ''}</FormHelperText>
          </FormControl>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            Save Settings
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Settings;
