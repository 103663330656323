import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { getSelectedClientId } from './common';
import TokenService from 'src/utils/tokenService';
import getSerializedParams from './url';
import { appInsights,severityLevel } from 'src/utils/appInsight';

// eslint-disable-next-line no-undef
const baseUrl: string = process.env.REACT_APP_API_URL;

interface HeaderTypeInterFace {
  Accept: string;
  'Content-Type': string;
  'Access-Control-Allow-Origin': string;
  'Authorization': string;
  'x-api-key': string;
}

interface ResponseInterface {
  data: object | object[] | string[];
  status: string | number;
}

const defaultHeaders: HeaderTypeInterFace = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Authorization': null,
  // eslint-disable-next-line no-undef
  'x-api-key': process.env.REACT_APP_X_API_KEY
};

const refreshTokenConstant = 'refreshToken';
const accessTokenConstant = 'accessToken';
const grantType = 'refresh_token';
const loginApiUrl = 'User/userLogin';

const refresh_Token = TokenService.getLocalRefreshToken(refreshTokenConstant);

function validateRefreshToken(refresh_token: any) {
  if (refresh_token) {
    const refresh_exp: number = jwt_decode(refresh_token);
    const date = Date.now();
    if (!!refresh_exp && date >= refresh_exp + (date + 259200000)) {
      window.location.href = '/';
    } else if (!refresh_exp) {
      window.location.href = '/';
    }
  }
}
function validateToken(token: string) {
  if (!token) {
    window.location.href = '/';
  } else if (token) {
    const exp:number= jwt_decode(token);
    if (!!exp && Date.now() >= exp * 1000) {
      window.location.href = '/';
    } else if (!exp) {
      window.location.href = '/';
    }
  }
}

function getTokenFromAuthorizationHeader(bearerHeader) {
  const parts = bearerHeader.split(' ');
  if (parts.length === 2) {
    const scheme = parts[0];
    const credentials = parts[1];

    if (/^Bearer$/i.test(scheme)) {
      return credentials;
    } else {
      return bearerHeader;
    }
  } else {
    return bearerHeader;
  }
}

const api = async function (
  endpoint: string,
  method: string,
  params: object = {},
  additionalHeaders: object = {},
  isTokenRequired = true
): Promise<any> {
  const getToken: string = localStorage.getItem('accessToken');
  const clientId: number = getSelectedClientId();

  if (getToken) {
    defaultHeaders.Authorization = `Bearer ${getToken}`;
  }

  if (!params.hasOwnProperty('clientId')) {
    params['clientId'] = clientId;
  }

  let headers: object = null;
  if (endpoint !== loginApiUrl) {
    headers = {
      ...defaultHeaders,
      ...additionalHeaders
    };
  }

  if (isTokenRequired) {
    validateToken(getTokenFromAuthorizationHeader(headers?.['Authorization']));
    validateRefreshToken(refresh_Token);
  }

  const fullUrl: string = baseUrl + endpoint;

  if (method.toLowerCase() === 'get') {
    const resp: ResponseInterface = await axios
      .get(fullUrl, {
        params: params,
        paramsSerializer: {
          serialize: (param) => getSerializedParams(param)
        },
        headers: headers
      })
      .then(function (response) {
        return { data: response.data, status: response.status };
      })
      .catch(async function (error) {
        appInsights.trackException({
          exception: error,
          severityLevel: severityLevel.Error
        });
        if (error.response) {
          if (error.response.status === 401) {
            const refreshTokenResponse = await refreshToken(error);
            if (refreshTokenResponse) {
              if (refreshTokenResponse.status === 200) {
                const accessToken = refreshTokenResponse.data.token;
                TokenService.updateLocalAccessToken(
                  accessToken,
                  accessTokenConstant
                );
                return await retryApiCall(
                  endpoint,
                  method,
                  params,
                  additionalHeaders
                );
              } else if (refreshTokenResponse.status === 400) {
                window.location.href = '/';
              }
            }
          } else {
            return {
              data: error.response.data,
              status: error.response.status
            };
          }
        }
      });

    return resp;
  } else if (method.toLowerCase() === 'post') {
    const resp: ResponseInterface = await axios
      .post(fullUrl, params, {
        headers: headers
      })
      .then(function (response) {
        return { data: response.data, status: response.status };
      })
      .catch(async function (error) {
        appInsights.trackException({
          exception: error,
          severityLevel: severityLevel.Error
        });
        if (error.response) {
          if (error.response.status === 401 && endpoint !== loginApiUrl) {
            const refreshTokenResponse = await refreshToken(error);
            if (refreshTokenResponse) {
              if (refreshTokenResponse.status === 200) {
                const accessToken = refreshTokenResponse.data.token;
                TokenService.updateLocalAccessToken(
                  accessToken,
                  accessTokenConstant
                );
                return await retryApiCall(
                  endpoint,
                  method,
                  params,
                  additionalHeaders
                );
              } else if (refreshTokenResponse.status === 400) {
                window.location.href = '/';
              }
            }
          } else {
            return {
              data: error.response.data,
              status: error.response.status
            };
          }
        }
      });

    return resp;
  } else if (method.toLowerCase() === 'put') {
    const resp: ResponseInterface = await axios
      .put(fullUrl, params, {
        headers: headers
      })
      .then(function (response) {
        return { data: response.data, status: response.status };
      })
      .catch(async function (error) {
        appInsights.trackException({
          exception: error,
          severityLevel: severityLevel.Error
        });
        if (error.response) {
          if (error.response.status === 401) {
            const refreshTokenResponse = await refreshToken(error);
            if (refreshTokenResponse) {
              if (refreshTokenResponse.status === 200) {
                const accessToken = refreshTokenResponse.data.token;
                TokenService.updateLocalAccessToken(
                  accessToken,
                  accessTokenConstant
                );
                return await retryApiCall(
                  endpoint,
                  method,
                  params,
                  additionalHeaders
                );
              } else if (refreshTokenResponse.status === 400) {
                window.location.href = '/';
              }
            }
          } else {
            return {
              data: error.response.data,
              status: error.response.status
            };
          }
        }
      });

    return resp;
  } else if (method.toLowerCase() === 'patch') {
    const resp: ResponseInterface = await axios
      .patch(fullUrl, params, {
        headers: headers
      })
      .then(function (response) {
        return { data: response.data, status: response.status };
      })
      .catch(async function (error) {
        appInsights.trackException({
          exception: error,
          severityLevel: severityLevel.Error
        });
        if (error.response) {
          if (error.response.status === 401) {
            const refreshTokenResponse = await refreshToken(error);
            if (refreshTokenResponse) {
              if (refreshTokenResponse.status === 200) {
                const accessToken = refreshTokenResponse.data.token;
                TokenService.updateLocalAccessToken(
                  accessToken,
                  accessTokenConstant
                );
                return await retryApiCall(
                  endpoint,
                  method,
                  params,
                  additionalHeaders
                );
              } else if (refreshTokenResponse.status === 400) {
                window.location.href = '/';
              }
            }
          } else {
            return {
              data: error.response.data,
              status: error.response.status
            };
          }
        }
      });

    return resp;
  } else if (method.toLowerCase() === 'delete') {
    const resp: ResponseInterface = await axios
      .delete(fullUrl, {
        headers: headers,
        data: params
      })
      .then(function (response) {
        return { data: response.data, status: response.status };
      })
      .catch(async function (error) {
        appInsights.trackException({
          exception: error,
          severityLevel: severityLevel.Error
        });
        if (error.response) {
          if (error.response.status === 401) {
            const refreshTokenResponse = await refreshToken(error);
            if (refreshTokenResponse) {
              if (refreshTokenResponse.status === 200) {
                const accessToken = refreshTokenResponse.data.token;
                TokenService.updateLocalAccessToken(
                  accessToken,
                  accessTokenConstant
                );
                return await retryApiCall(
                  endpoint,
                  method,
                  params,
                  additionalHeaders
                );
              } else if (refreshTokenResponse.status === 400) {
                window.location.href = '/';
              }
            }
          } else {
            return {
              data: error.response.data,
              status: error.response.status
            };
          }
        }
      });

    return resp;
  }
};

const refreshToken = async function (error: any): Promise<any> {
  const loginUrl: string = baseUrl + loginApiUrl;
  const originalConfig = error.config;
  if (originalConfig.url !== loginUrl && error.response) {
    if (error.response.status === 401) {
      const refreshToken =
        TokenService.getLocalRefreshToken(refreshTokenConstant);
      const rs = await axios
        .post(loginUrl, {
          grant_type: grantType,
          refresh_token: refreshToken,
          username: '',
          password: '',
          rememberMe: false
        })
        .then(function (response) {
          return { data: response.data, status: response.status };
        })
        .catch(async function (error) {
          appInsights.trackException({
            exception: error,
            severityLevel: severityLevel.Error
          });
          if (error.response.status) {
            return {
              data: error.response.data,
              status: error.response.status
            };
          }
        });
      return rs;
    }
  }
};

const refreshAndStoreToken = async function (): Promise<any> {
  const loginUrl: string = baseUrl + loginApiUrl;
  const refreshToken = TokenService.getLocalRefreshToken(refreshTokenConstant);
  const rs = await axios.post(loginUrl, {
      grant_type: grantType,
      refresh_token: refreshToken,
      username: '',
      password: '',
      rememberMe: false,
  })
  .then(function (response) {
      return { data: response.data, status: response.status };
  })
  .catch(async function (error) {
      if (error.response.status) {
          return {
              data: error.response.data,
              status: error.response.status
          };
      }
  });

  if (rs.status === 200) {
      const accessToken = rs.data.token;
      TokenService.updateLocalAccessToken(accessToken, accessTokenConstant);
  } else if (rs.status === 400) {
      window.location.href = '/';
  }

  return rs.data.token;
}

const retryApiCall = async function (
  endpoint: string,
  method: string,
  params: object = {},
  additionalHeaders: object = {}
) {
  return await api(endpoint, method, params, additionalHeaders);
};

export { api, baseUrl, refreshAndStoreToken };
