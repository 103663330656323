import { useSnackbar } from 'notistack';
import React, { createContext, FC, ReactNode, useEffect, useReducer, useRef, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import useSignalRAlertsListener from 'src/hooks/useSignalRAlertsListener';
import useSignalRConnection from 'src/hooks/useSignalRConnection';
import { Alert } from 'src/types/alert';
import { api } from 'src/utils/api';
import { playSound, getSelectedStoreId } from 'src/utils/common';
import { appInsights,severityLevel} from 'src/utils/appInsight';

interface AlertsState {
  alerts: Alert[];
}

interface AlertsContextValue {
  alerts: Alert[],
  setAlerts: (alerts: Alert[]) => void;
}

interface AlertsProviderProps {
  children: ReactNode;
}

type SetAlertsAction = {
  type: 'SET_ALERTS';
  payload: {
    alerts: Alert[];
  };
};

type Action = SetAlertsAction;

const initialAlertsState: AlertsState = {
  alerts: []
};

export const reducer = (state: AlertsState, action: Action): AlertsState => {
  switch (action.type) {
    case 'SET_ALERTS': {
      const { alerts } = action.payload;
      return {
        ...state,
        alerts
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AlertsContext = createContext<AlertsContextValue>({
  ...initialAlertsState,
  setAlerts: () => { },
});

const alertSound = '/static/sounds/pristine-609.mp3';

export const AlertsProvider: FC<AlertsProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAlertsState);
  const { enqueueSnackbar } = useSnackbar();
  const { signalRConnection } = useSignalRConnection();
  const [isAlertsLoaded, setIsAlertsLoaded] = useState<boolean>(false);
  const prevAlerts = useRef(state.alerts.length);
  const { isAuthenticated } = useAuth();

  const setAlerts = (alerts) => {
    if (alerts.length > prevAlerts.current) {
      playSound(alertSound);
    }
    prevAlerts.current = alerts.length;
    dispatch({
      type: 'SET_ALERTS',
      payload: { alerts }
    });
  };

  useEffect(() => {
    const getAlerts = async () => {
      try {
        const { data, status } = await api(`Alerts/storeId/${getSelectedStoreId()}`, 'get');
        appInsights.trackTrace({ message:'getALerts method called', properties:{'statusCode': status, 'responseData': data}, severityLevel: severityLevel.Information });

        if (status === 200) {
          dispatch({
            type: 'SET_ALERTS',
            payload: { alerts: data }
          });
          if (data?.length) {
            playSound(alertSound);
          }
        } else {
          enqueueSnackbar('An error while loading alerts!', {
            variant: 'error',
            autoHideDuration: 3000
          });
          appInsights.trackTrace({ message:'An error while loading alerts', properties:{'errorCode': status, 'responseData': data}, severityLevel: severityLevel.Information });
        }
        setIsAlertsLoaded(true);

      } catch (err) {
        console.error(err);
        appInsights.trackException({ exception: err, severityLevel: severityLevel.Error });
      }
    };
    if (isAuthenticated && getSelectedStoreId() > 0) {
      getAlerts();
    }
  }, [isAuthenticated]);

  useSignalRAlertsListener(signalRConnection, setAlerts, isAlertsLoaded, isAuthenticated);

  return (
    <AlertsContext.Provider
      value={{
        ...state,
        setAlerts,
      }}
    >
      {children}
    </AlertsContext.Provider>
  );
};

export default AlertsContext;