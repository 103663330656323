import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { User } from 'src/types/user';

export interface UserState {
  user: User;
}

const initialState: UserState = {
  user: null
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUser(state: UserState, action: PayloadAction<{ user: User; }>) {
      const { user } = action.payload;
      state.user = user;
    }
  }
});

export const reducer = slice.reducer;
export default slice;
