import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as userReducer } from 'src/slices/user';
import { reducer as stationReducer } from 'src/slices/station';
import { reducer as transactionReducer } from 'src/slices/transaction';
import { reducer as clientReducer } from 'src/slices/client';
import { reducer as serviceWorkerReducer } from 'src/slices/serviceWorker';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  auth: userReducer,
  station: stationReducer,
  transaction: transactionReducer,
  client: clientReducer,
  serviceWorker: serviceWorkerReducer,
});

export default rootReducer;
