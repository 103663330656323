import React from 'react';
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getSelectedClientId } from 'src/utils/common';

interface ClientGuardProps {
  children?: ReactNode;
}

const ClientGuard: FC<ClientGuardProps> = ({ children }) => {

  if (!getSelectedClientId()) {
    return <Navigate to="/choose-client" />;
  }

  return (
    <>
      {children}
    </>
  );
};

ClientGuard.propTypes = {
  children: PropTypes.node
};

export default ClientGuard;
