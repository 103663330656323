import _ from 'lodash';
import {
  colors,
  createTheme as createMuiTheme,
  responsiveFontSizes
} from '@material-ui/core';
import type { Theme as MuiTheme } from '@material-ui/core/styles';
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows';
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground
} from '@material-ui/core/styles/createPalette';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

interface TypeBackground extends MuiTypeBackground {
  dark: string;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
  customs?: any;
}

type Direction = 'ltr' | 'rtl';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  theme?: string;
}

interface ThemeOptions {
  name?: string;
  direction?: Direction;
  typography?: Record<string, any>;
  overrides?: Record<string, any>;
  palette?: Record<string, any>;
  shadows?: MuiShadows;
  customs?: any;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    }
  }
};

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.EXO_DARK,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#828382'
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: '#353635'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: '#212221'
      },
      secondary: {
        main: '#353635'
      },
      text: {
        primary: '#212221',
        secondary: '#353635'
      }
    },
    shadows: strongShadows,
    customs: {
      station: {
        red: '#FF5C59', // alert
        yellow: '#EFCE67', // inUse Working
        green: '#A5D562', // ready Waiting || sleeping
        grey: '#AEAEAE', // disabled
        blue: '#1f58ad',   //inUse
        darkBlue: '#163E7B',  //Resting
        purple: '#CCA1CC'   // Diagnostic, Mantenance
      },
      textOverLightShades: '#282C34',
      textOverDarkShades: '#EEEEEE',
      loader: {
        background: '#C7C7C7',
        foreground: '#f4f6f8',
      },
      transaction: {
        peachOrange: '#F9CB9C',
        orange: '#FF9900',
        whisper: '#EEEEEE',
        lightGreen: '#B6D7A8',
        green: '#7bb762',
      },
    },
  },
  {
    name: THEMES.EXO_LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: '#212221'
      },
      secondary: {
        main: '#80C41C'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows,
    customs: {
      station: {
        red: '#FF5C59',
        yellow: '#EFCE67',
        green: '#A5D562',
        grey: '#AEAEAE',
        blue: '#1f58ad',
        darkBlue: '#163E7B',  //Resting
        purple: '#CCA1CC'   // Diagnostic, Maintenance
      },
      textOverLightShades: '#282C34',
      textOverDarkShades: '#EEEEEE',
      loader: {
        background: '#C7C7C7',
        foreground: '#f4f6f8',
      },
      transaction: {
        peachOrange: '#F9CB9C',
        orange: '#FF9900',
        whisper: '#EEEEEE',
        lightGreen: '#B6D7A8',
        green: '#7bb762',
      },
    },
  },
  {
    name: THEMES.EXO_BLUE,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: '#163E7B'
      },
      secondary: {
        main: '#1f58ad'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows,
    customs: {
      station: {
        red: '#FF5C59',
        yellow: '#EFCE67',
        green: '#A5D562',
        grey: '#AEAEAE',
        blue: '#1f58ad',
        darkBlue: '#163E7B',  //Resting
        purple: '#CCA1CC'   // Diagnostic, Mantenance
      },
      textOverLightShades: '#282C34',
      textOverDarkShades: '#EEEEEE',
      loader: {
        background: '#C7C7C7',
        foreground: '#f4f6f8',
      },
      transaction: {
        peachOrange: '#F9CB9C',
        orange: '#FF9900',
        whisper: '#EEEEEE',
        lightGreen: '#B6D7A8',
        green: '#7bb762',
      },
    },
  },
  {
    name: THEMES.DARK_MODE,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#80C41C'
      },
      secondary: {
        main: '#80C41C'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      }
    },
    shadows: strongShadows,
    customs: {
      station: {
        red: '#FF5C59',
        yellow: '#EFCE67',
        green: '#A5D562',
        grey: '#AEAEAE',
        blue: '#3782f2',
        darkBlue: '#2e6dcc',  //Resting
        purple: '#CCA1CC'   // Diagnostic, Mantenance
      },
      textOverLightShades: '#282C34',
      textOverDarkShades: '#EEEEEE',
      loader: {
        background: '#ADB0BB',
        foreground: '#E6E5E8',
      },
      transaction: {
        peachOrange: '#F9CB9C',
        orange: '#FF9900',
        whisper: '#EEEEEE',
        lightGreen: '#B6D7A8',
        green: '#7bb762',
      },
    },
  },
];

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseOptions,
      themeOptions,
      { direction: config.direction }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme as Theme;
};
