import { colors } from '@material-ui/core';
import { sentenceCase } from 'change-case';
import { THEMES } from 'src/constants';
import { restoreSettings } from 'src/contexts/SettingsContext';
import { Theme } from 'src/theme';
import { RFIDReaderStatusToDisplay, StationRFIDReaderStatus, StationType, StatusDataType as StatusDataTypeInterface } from 'src/types/station';
import { TransactionStatus } from 'src/types/transaction';
import { api } from 'src/utils/api';

export const appType = 'Clerk';

export enum LogLevel {
  Trace = 'Trace',
}

export type LogToCloudType = {
  message: string,
  logLevel?: LogLevel,
  Source?: string,
  data?: any;
};

const playSound = (url) => {
  const audio = new Audio(url);
  audio.play().then().catch((e) => { console.log('e', e); });
};

export const arrayToJsonForOneProperty = (array, key, value, prefix = 'key') => {
  return array.reduce((accumulator, item) => {
    accumulator[prefix + item[key]] = item[value];
    return accumulator;
  }, {});
};

//remove html tags from a string, leaving only the inner text
export function removeHTML(str) {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || '';
}

export const truncate = (input, length = 25) => input.length > length ? `${input.substring(0, length)}...` : input;

export { playSound };

export const getTimeMinutes = (minutes: any) => {
  const sign = minutes < 0 ? '-' : '';
  const min = Math.floor(Math.abs(minutes));
  const sec = Math.floor((Math.abs(minutes) * 60) % 60);
  return `${sign + (min < 10 ? '0' : '') + min  }:${  sec < 10 ? '0' : ''  }${sec}`;
};

export const getColorByName = (name: string, theme: string) => {
  const character = name?.charAt(0)?.toUpperCase();
 
  const shade = theme === THEMES.DARK_MODE ? 200 : 800;
  switch (character) {
    case 'A':
      return colors.amber[shade];
    case 'B':
      return colors.blue[shade];
    case 'C':
      return colors.blueGrey[shade];
    case 'D':
      return colors.brown[shade];
    case 'E':
      return colors.cyan[shade];
    case 'F':
      return colors.deepOrange[shade];
    case 'G':
      return colors.deepPurple[shade];
    case 'H':
      return colors.green[shade];
    case 'I':
      return colors.grey[shade];
    case 'J':
      return colors.indigo[shade];
    case 'K':
      return colors.lightBlue[shade];
    case 'L':
      return colors.lightGreen[shade];
    case 'M':
      return colors.lime[shade];
    case 'N':
      return colors.orange[shade];
    case 'O':
      return colors.pink[shade];
    case 'P':
      return colors.purple[shade];
    case 'Q':
      return colors.red[shade];
    case 'R':
      return colors.teal[shade];
    case 'S':
      return colors.yellow[shade];
    case 'T':
      return colors.amber[shade];
    case 'U':
      return colors.blue[shade];
    case 'V':
      return colors.blueGrey[shade];
    case 'W':
      return colors.brown[shade];
    case 'X':
      return colors.cyan[shade];
    case 'Y':
      return colors.deepOrange[shade];
    case 'Z':
      return colors.deepPurple[shade];
    default:
      return colors.green[shade];
  }
};

export const logToCloud = ({ message = '', data = {}, logLevel = LogLevel.Trace }: LogToCloudType): void => {
  try {
    const requestPayload = {
      logLevel,
      message,
      source: appType,
      data
    };
    api('logging', 'post', requestPayload);
  } catch (e) {
    console.error(e);
  }
};

export const getSelectedClientId = () => {
  const settings: any = restoreSettings();
  return parseInt(settings?.client || 0);
};

export const getSelectedStoreId = () => {
  const settings: any = restoreSettings();
  return parseInt(settings?.store || 0);
};

export const startsWith = (str, word) => {
  return String(str).startsWith(word);
};

export const resetStation = async (stationId: string | number, selectedRecord: any) => {
  try {
    const isValidator = selectedRecord?.stationType === StationType.Validator;
    const params = {
      'isValidatorApp': isValidator ? true : false,
      'voidedFrom': appType
    };
    return await api(`stations/permanentId/${stationId}/reset`, 'post', params);
  } catch (e) {
    console.error('error', e);
    return {};
  }
};
export const getRfidReaderStatusToDisplay = (status: StationRFIDReaderStatus, theme: Theme): StatusDataTypeInterface => {
  const { customs: { station: { blue, green, purple, red, grey }, textOverDarkShades, textOverLightShades } } = theme;
  switch (status) {
    case StationRFIDReaderStatus.Listening:
      return {
        name: status,
        text: RFIDReaderStatusToDisplay.Scanning,
        textColor: textOverDarkShades,
        color: blue
      };
    case StationRFIDReaderStatus.Paused:
      return {
        name: status,
        text: RFIDReaderStatusToDisplay.Ready,
        textColor: textOverLightShades,
        color: green
      };
    case StationRFIDReaderStatus.Stopped:
      return {
        name: status,
        text: RFIDReaderStatusToDisplay.Stopped,
        textColor: textOverLightShades,
        color: purple
      };
    case StationRFIDReaderStatus.Disconnected:
      return {
        name: status,
        text: RFIDReaderStatusToDisplay.Disconnected,
        textColor: textOverDarkShades,
        color: red
      };
    case StationRFIDReaderStatus.Disabled:
      return {
        name: status,
        text: RFIDReaderStatusToDisplay.Disabled,
        textColor: textOverLightShades,
        color: grey
      };
    case StationRFIDReaderStatus.UnknownReader:
      return {
        name: status,
        text: RFIDReaderStatusToDisplay.UnknownReader,
        textColor: textOverDarkShades,
        color: red
      };
    default:
      return {
        name: '',
        text: 'No Reader',
        textColor: 'none',
        color: 'none'
      };
  }
};

export const getTransactionStatusToDisplay = (status: TransactionStatus) => {
  switch (status) {
    case TransactionStatus.Completed:
      return TransactionStatus.Completed;
    case TransactionStatus.Voided:
      return TransactionStatus.Voided;
    case TransactionStatus.CancelledByReset:
      return TransactionStatus.Voided;
    case TransactionStatus.Suspended:
      return TransactionStatus.Suspended;
    case TransactionStatus.Cancelled:
      return TransactionStatus.Voided;
    case TransactionStatus.InProgress:
      return sentenceCase(TransactionStatus.InProgress);
    default:
      return TransactionStatus.Completed;
  }
};

export const getOnLineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;