import { useEffect } from 'react';
import { getAll } from 'src/slices/station';
import { useDispatch } from 'src/store';
import { Alert } from 'src/types/alert';
import { getSelectedStoreId } from 'src/utils/common';
import { appInsights,severityLevel} from 'src/utils/appInsight';

const useSignalRAlertsListener = (signalRConnection, setAlerts, startConnection = false, isUserAuthenticated = false): null => {
  const dispatch = useDispatch();
  // const { setAlerts } = useContext(AlertsContext);

  useEffect(() => {
    if (signalRConnection?.connectionState === 'Disconnected' && startConnection && isUserAuthenticated) {
      signalRConnection.start()
        .then(async () => {
          console.info('SignalR Connected! getSelectedStoreId()', getSelectedStoreId());
          await signalRConnection.send('RegisterAsync', getSelectedStoreId());
          //await signalRConnection.send("RegisterAsync");
          signalRConnection.on('AlertAsync', (alerts: Alert[]) => {
            setAlerts(alerts);
            dispatch(getAll({ filters: { 'storeId': getSelectedStoreId(), 'isActive': true } }));
            appInsights.trackTrace({ message:'signalR : Listening to AlertAsync', properties:{'alerts': alerts}, severityLevel: severityLevel.Information });
          });
        })
        .catch(error => {
          console.log('SignalR Connection failed: ', error);
          appInsights.trackException({ exception: error, severityLevel: severityLevel.Error });
        });
      return () => {
       
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalRConnection, startConnection, isUserAuthenticated]);
  return null;
};

export default useSignalRAlertsListener;
