import axios from 'axios';

const getLocalRefreshToken = (key) => {
    const localRefreshToken = localStorage.getItem(key);
    return localRefreshToken;
  };
  const getLocalAccessToken = (key) => {
    const localAcessToken = localStorage.getItem(key);
    return localAcessToken;
  };
  const updateLocalAccessToken = (token, key) => {
    let accessToken = localStorage.getItem(key);
    accessToken = token;
    localStorage.setItem(key, accessToken);
  };
  const removeUser = (key) => {
    localStorage.removeItem(key);
  };
  const setSessionStorage = (key, value: string | null): void => {
    if (value) {
      localStorage.setItem(key, value);
      if(key === 'accessToken'){
        axios.defaults.headers.common.Authorization = `Bearer ${value}`;
      }
    } else {
      localStorage.removeItem(key);
      if(key === 'accessToken'){
        delete axios.defaults.headers.common.Authorization;
      }
    }
  };
  const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    removeUser,
    setSessionStorage
  };
  export default TokenService;