/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AppBar,
  Box,
  Hidden,
  HiddenProps,
  IconButton, makeStyles,
  SvgIcon, Toolbar, Typography
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';
import { Menu as MenuIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import Truncate from 'src/components/Truncate';
import { THEMES } from 'src/constants';
import { restoreSettings } from 'src/contexts/SettingsContext';
import type { Theme } from 'src/theme';
import Account from './Account';
import Settings from './Settings';
const FixHidden = Hidden as React.ComponentType<HiddenProps & {children?: React.ReactNode}>
interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.EXO_DARK || theme.name === THEMES.EXO_BLUE) ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...(theme.name === THEMES.DARK_MODE || theme.name === THEMES.EXO_LIGHT) ? {
      backgroundColor: theme.palette.background.default
    } : {},
    ...theme.name === THEMES.EXO_LIGHT ? {
      color: theme.palette.primary.main
    } : { color: '#ffffff' },
  },
  toolbar: {
    minHeight: 64
  },
  clientAndStoreName: {
    flexGrow: 1,
  }
}));

const TopBar: FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const settings: any = restoreSettings();
  const clientName = settings?.clientName || '';
  const storeName = settings?.storeName || '';

  const clientAndStore = (length: number) => {
    return <Typography variant="h5" className={classes.clientAndStoreName}>
      <Truncate value={clientName ? clientName : ''} length={length} />
      {storeName ? <> {'>'} <Truncate value={storeName} length={length} /></> : ''}
    </Typography>;
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <FixHidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
          <Box >
            {clientAndStore(30)}
          </Box>
        </FixHidden>
        <FixHidden mdDown>
          <RouterLink to="/">
            <Logo style={{ width: '100px' }} />
          </RouterLink>
          <Box ml={18}>
            {clientAndStore(50)}
          </Box>
        </FixHidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar >
    </AppBar >
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onMobileNavOpen: () => { }
};

export default TopBar;
