enum permissionType {
  Granted = 'granted',
  Denied = 'denied',
  Default = 'default'
}

const browserNotification = (notificationObj: Notification) => {
  const showNotification = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const notification = new Notification(notificationObj.title, {
      body: notificationObj.body,
      icon: `${window.location.origin}/static/icons/logo_x72.png`
    });
  };

  if (Notification.permission === permissionType.Granted) {
    showNotification();
  } else if (Notification.permission !== permissionType.Denied) {
    Notification.requestPermission().then((permission) => {
      if (permission === permissionType.Granted) {
        showNotification();
      }
    });
  }
};

export default browserNotification;
