import moment from 'moment';
export const initCustomLocal = () => {
	moment.locale('en-mobile', {
		parentLocale: 'en',
		relativeTime: {
			future: 'in %s',
			past: '%s ago',
			s: '%d s',
			m: '%d m',
			mm: '%d m',
			h: '%d h',
			hh: '%d h',
			d: '%d d',
			dd: '%d d',
			M: '%d mth',
			MM: '%d mths',
			y: '%d y',
			yy: '%d y'
		}
	});
};
