import mock from 'src/utils/mock';
// import { StationMode, StationNetwork, StationStatus } from 'src/types/station';
// import { layout } from 'src/types/stationGroup';
// import moment from 'moment';
import {
  Alert,
  // AlertType 
} from 'src/types/alert';

mock.onGet('/api/alerts').reply(() => {
  const alerts: Alert[] = [
    // {
    //   id: '5ecb868d0f437390ef3a836584',
    //   name: "",
    //   text: 'Alert 123',
    //   code: '123456',
    //   createdAt: moment()
    //     .subtract(32, 'minutes')
    //     .format('YYYY-MM-DD HH:mm:ss'),
    //   type: AlertType.shopperAssistance,
    //   station: {
    //     id: 1,
    //     macAddress: '51-7F-69-B7-20-43',
    //     name: 'Clone-I',
    //     network: StationNetwork.online,
    //     status: StationStatus.Ready,
    //     operatingMode: StationMode.normal,
    //     alert: null,
    //     agentSoftwareVersion: '1.0.7',
    //     installationDate: '2021-01-01',
    //     lastUpdateDate: '2021-02-01',
    //     currentCpu: 'i7',
    //     currentMemory: '16 GB',
    //     currentThreads: '12',
    //     antenna: 4,
    //     stationGroup: {
    //       id: 1,
    //       name: 'Front Station Group',
    //       locationDescription: 'Hall 1 first floor',
    //       layout: layout.square,
    //       updatedAt: moment()
    //         .subtract(1, 'days')
    //         .subtract(7, 'hours')
    //         .toDate()
    //         .getTime()
    //     },
    //   },
    //   closedBy: {
    //     id: '5e86809283e28b96d2d38537',
    //     avatar: '/static/images/avatars/avatar_6.png',
    //     canHire: false,
    //     country: 'USA',
    //     email: 'arutter@starkrfid.com',
    //     isPublic: true,
    //     name: 'Katarina Smith',
    //     password: 'P@55w0rd!',
    //     phone: '+40 777666555',
    //     role: 'admin',
    //     state: 'New York',
    //     tier: 'Premium'
    //   }
    // }
  ];
  return [200, { alerts }];
});
