import moment from 'moment';
import mock from 'src/utils/mock';
import type { Product } from 'src/types/product';
import { ProductType } from 'src/types/product';

const products: Product[] = [
  {
    id: '5ece2c077e39da27658aa8a9',
    name: 'Cap',
    description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.',
    epcPrefix: 'abc',
    sku: '123',
    type: ProductType.type1,
    iconUrl: '',
    imageUrl: 'https://assets.ajio.com/medias/sys_master/root/hb5/h1e/13857996472350/-1117Wx1400H-460411581-white-OUTFIT.jpg',
    manufacturer: 'Addidas',
    upc: '1234567',
    updatedAt: moment()
      .subtract(6, 'hours')
      .toDate()
      .getTime(),
  },
  {
    id: '5ece2c077e39da27658aa8a9',
    name: 'Mug',
    description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.',
    epcPrefix: 'abc',
    sku: '123',
    type: ProductType.type2,
    iconUrl: '',
    imageUrl: 'https://images-na.ssl-images-amazon.com/images/I/71S8zUoBskL._SL1500_.jpg',
    manufacturer: 'Nestasia',
    upc: '1234567',
    updatedAt: moment()
      .subtract(6, 'hours')
      .toDate()
      .getTime(),
  },
  {
    id: '5ece2c077e39da27658aa8a9',
    name: 'Ball',
    description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.',
    epcPrefix: 'abc',
    sku: '123',
    type: ProductType.type3,
    iconUrl: '',
    imageUrl: 'https://www.sportsballshop.co.uk/acatalog/WilsonOfficialLeague1.jpg',
    manufacturer: 'Wilson',
    upc: '1234567',
    updatedAt: moment()
      .subtract(6, 'hours')
      .toDate()
      .getTime(),
  },
];

mock.onGet('/api/products').reply(200, { products });

const product: Product = {
  id: '5ece2c077e39da27658aa8a9',
  name: 'Cap',
  description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.',
  epcPrefix: 'abc',
  sku: '123',
  type: ProductType.type1,
  iconUrl: '',
  imageUrl: 'https://assets.ajio.com/medias/sys_master/root/hb5/h1e/13857996472350/-1117Wx1400H-460411581-white-OUTFIT.jpg',
  manufacturer: 'Addidas',
  upc: '1234567',
  updatedAt: moment()
    .subtract(6, 'hours')
    .toDate()
    .getTime(),
};
mock.onGet('/api/products/5ece2c077e39da27658aa8a9').reply(200, { product });